<template>
  <!-- eslint-disable -->
	<div class="page d-flex flex-row flex-column-fluid">
		<div class="wrapper d-flex flex-column flex-row-fluid">
			<div class="toolbar py-5 py-lg-15">
				<div id="kt_toolbar_container" class="container-xxl d-flex flex-stack flex-wrap">
					<h3 class="text-white fw-bolder fs-2qx me-5">Konto Klienta API</h3>
				</div>
			</div>
			<div class="d-flex flex-column-fluid align-items-start container-xxl">
				<div class="content flex-row-fluid">
					<div class="d-flex flex-column flex-xl-row">
            <Sidebar :id="userId" />
            <router-view v-if="organisation" />
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Sidebar from '@/components/Sidebar.vue';
export default {
  name: 'Organisation',
  inject: ['$profile'],
  props: ['id'],
  data() {
    return {
      userId: false,
      organisation: {},
    }
  },
  provide() {
    return {
      $organisation: () => this.organisation,
    }
  },
  mounted() {
  /*
    if (this.profile && this.profile.is_admin && this.$router.path() == '/dashboard')
    {
      this.$router.push('/admin');
    }
    */
    if (this.id)
    {
      this.userId = this.id;
    }
    else
    {
      this.userId = 'me';
    }
    this.updateOrganisation();
  },
  methods: {
    updateOrganisation: function() {
      var self = this;
      self.$root.$refs.api.get('organisations/' + this.userId).then(function (response) {
        if (typeof response.data !== 'undefined')
        {
          self.organisation = response.data;
        }
      })
      .catch(function () {
        self.organisation = {};
      });
    }
  },
  computed: {
    profile() {
      return this.$profile();
    }
  },
  components: {
    Sidebar
  }
};
</script>

<style>
ul.roles {
  list-style-type: none;
  padding: 0;
}
ul.roles li {
  padding: 0;
  padding-bottom: 8px;
}
</style>
